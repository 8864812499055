import "./modal.scss";
import { RiCloseLine } from "react-icons/ri";
import { createPortal } from "react-dom";

const Modal = ({ show, close, children }) => {
  return createPortal(
    <>
      <div
        className={`modalContainer ${show ? "show" : ""} `}
        onClick={() => close()}
      >
         <button className="closeBtn" onClick={() => close()}>
            <RiCloseLine style={{ marginBottom: "-3px" }} /> 
          </button>
        <div className="modal" onClick={(e) => e.stopPropagation()}>
         <main className="modal_content">{children}</main>
            </div>
      </div>
    </>,
    document.getElementById("modal")
  );
};

export default Modal;
